namespace UBoolean {
  export type Falsy = false | 0 | "" | null | undefined;

  export const isTruthy = <T,>(x: T): x is Exclude<T, Falsy> => !!x;
  export const isFalsy = <T,>(x: T): x is Extract<T, Falsy> => !x;

  export const xor = (a: any, b: any): boolean => (a && !b) || (!a && b);
  export const xnor = (a: any, b: any): boolean => !UBoolean.xor(a, b);

  export const returnsTrue = () => true;
  export const returnsFalse = () => false;
}

export default UBoolean;
